import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorRoute } from '../../page/error/routes';
import { useNavigate } from 'react-router-dom';
import { AuthService } from '../../auth/auth.service';

interface CustomMessage {
  key: string;
  params?: any[];
}

export interface CustomErrorMessage {
  badRequest?: CustomMessage;
  unauthorized?: CustomMessage;
  accessDenied?: CustomMessage;
  notFound?: CustomMessage;
  concurrency?: CustomMessage;
  internal?: CustomMessage;
}

export function useErrorHandler() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const defaultPrefix = 'error:httpError.';
  const apiPrefix = 'error:apiError.';

  const getMessage = (cm: CustomMessage | undefined, error: any, defaultKey: string) =>
    t(cm?.key ?? `${apiPrefix}${error.data?.errors?.[0]?.code}`, {
      defaultValue: t(`${defaultPrefix}${defaultKey}`),
      ...(cm?.params ?? []),
    });


  return useCallback((name: string, error: any, customMessage?: CustomErrorMessage) => {
    error = error?.response ? error.response : error;
    console.log(error)
    if (error && error.status === 400) {
      console.error(name, 'validation error', error.data.errors);
      enqueueSnackbar(
        getMessage(customMessage?.badRequest, error, 'badRequest'),
        { variant: 'error' },
      );
      return  error.data.errors ?? null;
    }

    if (error && error.status === 401) {
      console.error(name, 'authorize error');
      enqueueSnackbar(
        getMessage(customMessage?.unauthorized, error, 'unauthorized'),
        { variant: 'error' },
      );
      AuthService.signOut();
      return navigate(ErrorRoute.UNAUTHORIZED);
    }

    if (error && error.status === 403) {
      console.error(name, 'access error');
      enqueueSnackbar(
        getMessage(customMessage?.accessDenied, error, 'accessDenied'),
        { variant: 'error' },
      );
      return ErrorRoute.ACCESS_DENIED;
    }

    if (error && error.status === 404) {
      console.error(name, 'not found error');
      enqueueSnackbar(
        getMessage(customMessage?.notFound, error, 'notFound'),
        { variant: 'error' },
      );
      return ErrorRoute.NOT_FOUND;
    }

    if (error && error.status === 409) {
      console.error(name, 'concurrency error');
      enqueueSnackbar(
        getMessage(customMessage?.concurrency, error, 'conflict'),
        { variant: 'error' },
      );
      return null;
    }

    if (error && error.status === 500) {
      console.error(name, error);
      enqueueSnackbar(
        getMessage(customMessage?.internal, error, 'internal'),
        { variant: 'error' },
      );
      return null;
    }
    console.error(name, error);
  }, []);
}
