import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StandardCSSProperties } from '@mui/system/styleFunctionSx/StandardCssProperties';

interface IProps {
  rightPadding?: StandardCSSProperties['paddingRight'];
}
export function Contacts(props: IProps) {
  const { t } = useTranslation('common', { keyPrefix: 'footer' });
  return (
    <Box sx={{ textAlign: 'center' }}>
      <Stack direction="row" spacing={1} mt={4} mx="auto" display="inline-flex" pr={props.rightPadding} >
        <a target="_blank"
           href="tel:+74951099845"
           style={{ color: '#0B5394', fontSize: 16 }}>
          <img src="https://stripo.email/static/assets/img/other-icons/rounded-gray/phone-rounded-gray.png"
               alt="Phone" title="Phone" width="32" height="32"
               style={{ display: 'block', border: 0, outline: 'none', textDecoration: 'none' }}/>
        </a>
        <a target="_blank"
           href="https://t.me/signx_bot"
           style={{ color: '#0B5394', fontSize: 16 }}>
          <img src="https://stripo.email/static/assets/img/messenger-icons/rounded-gray/telegram-rounded-gray.png"
               alt="Telegram" title="Telegram" width="32" height="32"
               style={{ display: 'block', border: 0, outline: 'none', textDecoration: 'none' }}/>
        </a>
        <a target="_blank"
           href="https://wa.me/79777879793"
           style={{ color: '#0B5394', fontSize: 16 }}>
          <img src="https://stripo.email/static/assets/img/messenger-icons/rounded-gray/whatsapp-rounded-gray.png"
               alt="Whatsapp" title="Whatsapp" width="32" height="32"
               style={{ display: 'block', border: 0, outline: 'none', textDecoration: 'none' }}/>
        </a>
        <a target="_blank"
           href="mailto:info@signx.cloud"
           style={{ color: '#0B5394', fontSize: 16 }}>
          <img src="https://stripo.email/static/assets/img/other-icons/rounded-gray/mail-rounded-gray.png"
               alt="Email" title="Email" width="32" height="32"
               style={{ display: 'block', border: 0, outline: 'none', textDecoration: 'none' }}/>
        </a>
        <a target="_blank"
           href="https://signx.cloud/"
           style={{ color: '#0B5394', fontSize: 16 }}>
          <img src="https://stripo.email/static/assets/img/other-icons/rounded-gray/link-rounded-gray.png"
               alt="Website" title="Website" width="32" height="32"
               style={{ display: 'block', border: 0, outline: 'none', textDecoration: 'none' }}/>
        </a>
        <a target="_blank"
           href="https://www.youtube.com/channel/UCI_s95ZdWCjcBYeKC6b_HTA"
           style={{ color: '#0B5394', fontSize: 16 }}>
          <img src="https://fhsjnbx.stripocdn.email/content/assets/img/social-icons/rounded-gray/youtube-rounded-gray.png"
               alt="Yt" title="Youtube" width="32" height="32"
               style={{ display: 'block', border: 0, outline: 'none', textDecoration: 'none' }}/>
        </a>
      </Stack>
      <Typography variant="body1" pr={props.rightPadding} mt={2}>
        <span dangerouslySetInnerHTML={{ __html: t('contacts') ?? '' }}/><br/>
        {t('address')}
      </Typography>
    </Box>
  )
}