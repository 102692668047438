import { Box, Typography } from '@mui/material';
import React from 'react';
import { AuthService } from '../../../../auth/auth.service';
import { StandardCSSProperties } from '@mui/system/styleFunctionSx/StandardCssProperties';

interface IProps {
  rightPadding?: StandardCSSProperties['paddingRight'];
}

export function UnauthorizedHeader(props: IProps) {
  const user = AuthService.getUser();
  return <>{!user && <Box>
    <Typography variant='h3' textAlign='center' mt={2} pr={props.rightPadding}>
      SIGN
      <Typography variant='h3' component='span' color={'#27B152'}>X</Typography>
    </Typography>
  </Box>}</>;
}