import { AuthService } from '../../../../auth/auth.service';
import { Header, Footer, LeftMenu } from '../';
import React, { useState } from 'react';
import { Box, CssBaseline, Grid, Toolbar } from '@mui/material';
import { UnauthorizedRoutes } from '../../../../page/route/UnauthorizedRoutes';
import { AuthorizedRoutes } from '../../../../page/route/AuthorizedRoutes';
import { useCookies } from 'react-cookie';
import { PageContext } from './page.context';
import { useDocumentTitle } from '../../../../hooks/UseDocumentTitle';
import { MetaTags } from "../MetaTags";

export function Page() {
  useDocumentTitle();
  const user = AuthService.getUser();
  const [menuOpen, setMenuOpen] = React.useState(true);
  const toggleDrawer = () => {
    setMenuOpen(!menuOpen);
  };
  const [cookies, setCookie] = useCookies([`admin-switch-${user?.uuid}`]);
  const [adminMenu, setAdminMenu] = useState(cookies[`admin-switch-${user?.uuid}`] === 'true');
  const toggleAdminManu = (state?: boolean) => {
    setCookie(`admin-switch-${user?.uuid}`, !adminMenu);
    setAdminMenu(state ?? !adminMenu);
  }

  return (
    <PageContext.Provider value={{ adminMenu, toggleAdminMenu: toggleAdminManu }}>
      <MetaTags />
      {!user ? (
        <>
          <UnauthorizedRoutes />
        </>
      ) : (
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <Header menuOpen={menuOpen} toggleDrawer={toggleDrawer} />
          <LeftMenu menuOpen={menuOpen} toggleDrawer={toggleDrawer} />
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: '100vh',
              overflow: 'auto',
            }}
          >
            <Toolbar />
            <Grid container spacing={2} sx={{ mt: 0, mb: 2, pl: 1, pr: 1 }}>
              <AuthorizedRoutes />
            </Grid>
            <Footer />
          </Box>
        </Box>
      )}
    </PageContext.Provider>
  );
}
