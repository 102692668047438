import { Box, Typography } from '@mui/material';
import { TypographyProps } from '@mui/material/Typography/Typography';
import { useQuery } from 'react-query';
import axios from 'axios';
import { API_URL } from '../../../../model/constants';
import { Contacts } from '../Contacts';
import React from 'react';

export function Footer(props: TypographyProps) {
  const { data: apiBuild } = useQuery('build', () => process.env.REACT_APP_BRANCH
    ? axios.get<string>(`${API_URL}buildInfo`)
    : Promise.resolve(null),
  );

  return (
    <Box>
      <Contacts />
      <Typography variant='body2' color='text.secondary' align='center' {...props}>
        {`Copyright © ${window.location.host} ${new Date().getFullYear()}.`}
        <br/>
        {`${apiBuild?.data}/${process.env.REACT_APP_BRANCH}`}
      </Typography>
    </Box>
  );
}
