import {
  Avatar,
  Box,
  Button,
  Container,
  CssBaseline,
  Typography,
} from '@mui/material';
import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Contacts, PasswordControl, UnauthorizedHeader } from '../../../components/common';
import { useTranslation } from 'react-i18next';
import { NotEmpty, StrongPassword } from '../../../hooks/UseValidation/validators';
import ValidationContext from '../../../hooks/UseValidation/validation.context';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useErrorHandler } from '../../../hooks/UseErrorHandler';
import { ChangePasswordDto } from '../../../model';
import { ChangePasswordValidator } from '../../../service/validator/user';
import { UserService } from '../../../api/user/user.service';
import { AuthService } from '../../../auth/auth.service';

export function ChangePassword() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [token] = useState<string | null>(searchParams.get('token'));
  const [redirectUrl] = useState<string | null>(searchParams.get('redirectUrl'));
  const user = AuthService.getUser();
  const { t } = useTranslation('user', { keyPrefix: 'changePassword' });
  const errorHandler = useErrorHandler();
  const [validateOn, setValidateOn] = useState(false);
  const [dto, setDto] = useState<ChangePasswordDto>(new ChangePasswordDto());
  const updateDto = (field: keyof ChangePasswordDto, value: any) => {
    setDto((prev) => ({ ...prev, [field]: value }));
  };
  const navigate = useNavigate();
  const [mismatchError, setMismatchError] = useState<string | null>(null);

  useEffect(() => {
    setSearchParams(new URLSearchParams());
  }, [token]);

  const onSubmit = useCallback(async (e: FormEvent, dto: ChangePasswordDto, token: string | null) => {
    e.preventDefault();
    setValidateOn(true);
    window.scrollTo(0, 0);
    const errors = ChangePasswordValidator.validate(dto, token === null);
    if (errors.length === 0) {
      setMismatchError(null);
      try {
        const uuid = await UserService.changePassword(dto, token);
        if (uuid !== null) {
          if (!redirectUrl) {
            navigate('/sign-in');
          } else {
            window.location.replace(redirectUrl);
          }
        }
      } catch (e) {
        errorHandler('signIn', e, {
          notFound: { key: 'user:error.passwordChangeToken' },
          accessDenied: { key: 'user:error.passwordChangeDenied' },
        });
      }
    } else if (errors.includes('mismatch')) {
      setMismatchError('user:error.passwordConfirmationMismatch')
    }
  }, []);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <UnauthorizedHeader/>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t('pageTitle')}
        </Typography>
        <Box component="form" onSubmit={(e) => onSubmit(e, dto, token)} noValidate sx={{ mt: 1 }} autoComplete="on">
          <ValidationContext.Provider value={{ validateOn }}>
            {!token && <PasswordControl
              name="oldPassword"
              required
              labelKey="user:changePassword.field.oldPassword"
              value={dto.oldPassword}
              onChange={(value) => updateDto('oldPassword', value)}
              validators={[NotEmpty]}
              size="medium"
            />}
            <PasswordControl
              name="password"
              required
              labelKey="user:changePassword.field.password"
              value={dto.password}
              onChange={(value) => updateDto('password', value)}
              validators={[NotEmpty, StrongPassword]}
              size="medium"
            />
            <PasswordControl
              name="passwordConfirmation"
              required
              labelKey="user:changePassword.field.passwordConfirmation"
              value={dto.passwordConfirmation}
              onChange={(value) => updateDto('passwordConfirmation', value)}
              validators={[NotEmpty]}
              size="medium"
              error={mismatchError}
            />
          </ValidationContext.Provider>
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
            {t('submit')}
          </Button>
        </Box>
      </Box>
      {!user && <Contacts />}
    </Container>
  );
}
