import { useTranslation } from 'react-i18next';
import { DataTable, Title } from '../../../components/common';
import { useFilter } from './filter.definition';
import { useColumns } from './table.definition';
import { EntityQueryKey } from '../../../query/query.keys';
import { useActions } from './action.definition';
import { CertificateItemBrief, CertificateTypeEnum } from '../../../model';
import { CertificateService } from '../../../api/certificate/certificate.service';

interface IProps {
  type: CertificateTypeEnum;
  staticFilter?: any;
  short?: boolean,
}

export function CertificateList({ type, staticFilter, short = false }: IProps) {
  const { t } = useTranslation();
  const columns = useColumns(type, short);
  const filter = useFilter(type);
  const [rowActions, groupActions, toolbarActions] = useActions();

  return (
    <>
      {short !== true && <Title text={t(`certificate:list.${type}.pageTitle`)} />}
      <DataTable
        id="certificate-list"
        queryKey={[EntityQueryKey.Certificate, JSON.stringify(staticFilter ?? {})]}
        columns={columns}
        fetchData={(requestData) => CertificateService.listFn<CertificateItemBrief>({ ...requestData, filter: { ...(staticFilter ?? requestData.filter) }, skipLoad: short })}
        filter={staticFilter ? [] : filter}
        sortBy="createDate"
        rowActions={rowActions}
        groupActions={groupActions}
        toolbarActions={toolbarActions}
      />
    </>
  );
}