import { Container, CssBaseline } from '@mui/material';
import React from 'react';
import { Footer, UnauthorizedHeader } from '../../components/common';

export function Main() {
  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <UnauthorizedHeader/>
      <Footer />
    </Container>
  )
}