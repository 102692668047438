import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import React, { useMemo } from "react";
import { Helmet } from "react-helmet-async";

interface IProps {
  metaKey?: string;
}

export function MetaTags(props: IProps) {
  const { t } = useTranslation('meta');
  const { pathname } = useLocation();
  const routeParams = useParams();

  const metaKey = useMemo(() => {
    if (props.metaKey) return props.metaKey;

    let defaultKey = pathname;
    if (Object.keys(routeParams).length) {
      Object.entries(routeParams).forEach(([paramName, paramValue]) => {
        if (paramValue) {
          defaultKey = defaultKey.replace(paramValue, `:${paramName}`);
        }
      });
    }
    return defaultKey;
  }, [props.metaKey, pathname, routeParams]);

  return <Helmet>
    {Object.entries(t(metaKey, { returnObjects: true, defaultValue: {} })).map(([key, value]) => <meta key={key} name={key} content={value} />)}
  </Helmet>;
}