import { UserAction } from '../../../model/actions/user.action';
import { EmployeeBrief } from '../../../model';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import { AuthService } from '../../../auth/auth.service';
import {
  useAddAction,
  useEditAction,
  useRemoveAction,
  useToggleActiveAction,
} from '../../../model/actions/common.actions';
import { EMPLOYEE_ROUTES } from '../../route';
import { EmployeeService } from '../../../api/employee/employee.service';
import { CertificateService } from '../../../api/certificate/certificate.service';
import { useQueryClient } from 'react-query';
import { resetQueryFn } from '../../../utils';
import { EntityQueryKey } from '../../../query/query.keys';

type AvailableActions =
  'add'
  | 'edit'
  | 'remove'
  | 'toggleActive'
  | 'toggleAdmin'
  | 'toggleConfirmed'
  | 'toggleDefault'
  | 'issueCertificate';

export function useActions(): Record<AvailableActions, UserAction<EmployeeBrief>> {
  const { t } = useTranslation();
  const user = useMemo(() => AuthService.getUser(), []);
  const canModify = useCallback(
    (entity?: EmployeeBrief) => entity != undefined && !entity.isDeleted && user != null && user.isAdmin,
    [user],
  );
  const add = useAddAction<EmployeeBrief>(EMPLOYEE_ROUTES);
  const edit = useEditAction<EmployeeBrief>(EMPLOYEE_ROUTES, (entity) => !entity?.isVerified && canModify(entity));
  const remove = useRemoveAction<EmployeeBrief>(EmployeeService, (entity) => canModify(entity));
  const toggleActive = useToggleActiveAction<EmployeeBrief>({
    service: EmployeeService,
    available: (entity) => canModify(entity),
    localeConfirmNS: 'employee',
  });

  const queryClient = useQueryClient();

  return {
    add,
    edit,
    remove,
    toggleActive,
    toggleAdmin: {
      key: 'toggleAdmin',
      btnText: (entity) => t(`employee:button.toggleAdmin.${!entity?.isAdmin}`),
      btnColor: (entity) => entity?.isAdmin ? 'warning' : 'success',
      available: (entity) => canModify(entity),
      action: (entity) => EmployeeService.toggle('isAdmin', entity?.uuid ?? '', !entity?.isAdmin),
      confirm: (entity) => ({
        description: `employee:confirm.toggleAdmin.${!entity?.isAdmin}`,
      }),
    },
    toggleConfirmed: {
      key: 'toggleConfirmed',
      btnText: (entity) => t(`employee:button.toggleConfirmed.${!entity?.isConfirmed}`),
      btnColor: (entity) => entity?.isConfirmed ? 'warning' : 'success',
      available: (entity) => canModify(entity),
      action: (entity) => EmployeeService.toggle('isConfirmed', entity?.uuid ?? '', !entity?.isConfirmed),
      confirm: (entity) => ({
        description: `employee:confirm.toggleConfirmed.${!entity?.isConfirmed}`,
      }),
    },
    toggleDefault: {
      key: 'toggleDefault',
      btnText: (entity) => t(`employee:button.toggleDefault.${!entity?.isDefault}`),
      btnColor: (entity) => entity?.isDefault ? 'warning' : 'success',
      available: (entity) => canModify(entity),
      action: (entity) => EmployeeService.toggle('isDefault', entity?.uuid ?? '', !entity?.isDefault),
      confirm: (entity) => ({
        description: `employee:confirm.toggleDefault.${!entity?.isDefault}`,
      }),
    },
    issueCertificate: {
      key: 'issueCertificate',
      btnText: () => t('employee:button.issueCertificate'),
      btnColor: () => 'success',
      available: (entity) => canModify(entity) && entity?.client?.isVerified === true && entity.isPhysicalVerified && !entity.isCertificateExists,
      action: async (entity) => {
        await CertificateService.issue(entity?.uuid ?? '');
        await queryClient.resetQueries({ predicate: (query) => resetQueryFn(query, EntityQueryKey.Certificate) });
      },
      confirm: () => ({
        description: 'employee:confirm.issueCertificate',
      }),
    },
  };
}