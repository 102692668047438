import { Avatar, Box, Button, Container, CssBaseline, Link, Typography } from '@mui/material';
import React, { FormEvent, useCallback, useContext, useState } from 'react';
import { AuthService } from '../../../auth/auth.service';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Footer, UnauthorizedHeader } from '../../../components/common';
import { useTranslation } from 'react-i18next';
import InputControl from '../../../components/common/ui/InputControl';
import { IsEmail, NotEmpty } from '../../../hooks/UseValidation/validators';
import ValidationContext from '../../../hooks/UseValidation/validation.context';
import { SignInValidator } from '../../../service/validator';
import { useNavigate, Link as RouteLink } from 'react-router-dom';
import { useErrorHandler } from '../../../hooks/UseErrorHandler';
import { LoginDto } from '../../../model';
import { PageContext } from '../../../components/common/layout/Page/page.context';
import { RestoreRoute } from '../../restore/routes';

export function AdminLogin() {
  const { t } = useTranslation('admin', { keyPrefix: 'signIn' });
  const { toggleAdminMenu } = useContext(PageContext);
  const errorHandler = useErrorHandler();
  const [validateOn, setValidateOn] = useState(false);
  const [dto, setDto] = useState<LoginDto>(new LoginDto());
  const updateDto = (field: keyof LoginDto, value: any) => {
    setDto((prev) => ({ ...prev, [field]: value }));
  };
  const navigate = useNavigate();

  const login = useCallback(async (e: FormEvent, dto: LoginDto) => {
    e.preventDefault();
    setValidateOn(true);
    window.scrollTo(0, 0);
    const errors = SignInValidator.validate(dto);
    if (errors.length === 0) {
      try {
        const uuid = await AuthService.signIn(dto.email, dto.password, true);
        if (uuid !== null) {
          toggleAdminMenu(true);
          navigate('/admin');
        }
      } catch (e) {
        errorHandler('signIn', e, {
          notFound: { key: 'admin:signIn.error.notFound' },
          badRequest: { key: 'admin:signIn.error.notFound' },
        });
      }
    }
  }, []);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <UnauthorizedHeader/>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t('pageTitle')}
        </Typography>
        <Box component="form" onSubmit={(e) => login(e, dto)} noValidate sx={{ mt: 1 }} autoComplete="on">
          <ValidationContext.Provider value={{ validateOn }}>
            <InputControl
              name="username"
              required
              labelKey="admin:signIn.field.email"
              autoComplete="username email"
              value={dto.email}
              onChange={(value) => updateDto('email', value)}
              validators={[NotEmpty, IsEmail]}
              size="medium"
            />
            <InputControl
              name="password"
              required
              labelKey="admin:signIn.field.password"
              type="password"
              autoComplete="current-password"
              value={dto.password}
              onChange={(value) => updateDto('password', value)}
              validators={[NotEmpty]}
              size="medium"
            />
          </ValidationContext.Provider>
          <Link component={RouteLink} to={RestoreRoute.FORGOT_PASSWORD}>
            {t('forgotLink')}
          </Link>
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
            {t('submit')}
          </Button>
        </Box>
      </Box>
      <Footer sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}
