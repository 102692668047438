import { useSearchParams } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Box, CircularProgress, Container, CssBaseline, Typography } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { UserService } from '../../../api/user/user.service';
import { useErrorHandler } from '../../../hooks/UseErrorHandler';
import { useSnackbar } from 'notistack';
import { Contacts, UnauthorizedHeader } from '../../../components/common';

export function ConfirmUserSettings() {
  const [searchParams, ] = useSearchParams();
  const [token] = useState<string | null>(searchParams.get('token'));
  const { t } = useTranslation('account', { keyPrefix: 'settings.confirm' });
  const errorHandler = useErrorHandler();
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(true);
  const effectRan = useRef(false);

  useEffect(() => {
    (async () => {
      if (effectRan.current) {
        return
      }

      if (!token) {
        enqueueSnackbar(t('error.noToken'), { variant: 'error' });
        return;
      }

      try {
        const updated = await UserService.confirmSettings(token);
        if (updated) {
          enqueueSnackbar(t('message.success'), { variant: 'success' });
        } else {
          enqueueSnackbar(t('message.error'), { variant: 'error' });
        }
      } catch (e) {
        errorHandler('confirmUserSettings', e, { internal: { key: 'account:settings.confirm.error' } });
      } finally {
        setLoading(false);
      }
    })();

    return () => {
      setLoading(false);
      effectRan.current = true;
    }
  }, []);

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <UnauthorizedHeader/>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t('pageTitle')}
        </Typography>
          {loading && <CircularProgress />}
      </Box>
      <Contacts />
    </Container>
  )
}