import { useSearchParams } from 'react-router-dom';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  CircularProgress,
  Container,
  CssBaseline,
  IconButton,
  Stack,
  Tooltip,
} from '@mui/material';
import { UserService } from '../../../api/user/user.service';
import { useErrorHandler } from '../../../hooks/UseErrorHandler';
import { useSnackbar } from 'notistack';
import { Contacts, UnauthorizedHeader } from '../../../components/common';
import { AuthService } from '../../../auth/auth.service';
import InputControl from '../../../components/common/ui/InputControl';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CopyAllOutlined } from '@mui/icons-material';
import { useQuery } from 'react-query';
import { EntityQueryKey } from '../../../query/query.keys';

export function ApiKey() {
  const [searchParams, ] = useSearchParams();
  const [token] = useState<string | null>(searchParams.get('token'));
  const user = AuthService.getUser();
  const { t } = useTranslation('account', { keyPrefix: 'confirmEmail' });
  const errorHandler = useErrorHandler();
  const { enqueueSnackbar } = useSnackbar();
  const [apiKey, setApiKey] = useState('');

  const { isLoading } = useQuery([EntityQueryKey.User, 'api-key'], () => UserService.getApiKey(token), {
    onSuccess: (data) => {
      setApiKey(data);
    },
    onError: (err) => {
      console.log(err);
      errorHandler('get api key', err);
    },
  });

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <UnauthorizedHeader rightPadding="70px"/>
      <Box sx={{ marginTop: 2, textAlign: 'center' }}>
        {isLoading && <CircularProgress />}
        {!isLoading && <Stack direction="column">
          {/*<Typography variant="h5">{t(messageKey)}</Typography>*/}
          <Stack direction="row" alignItems="start">
            <InputControl labelKey="account:confirmEmail.apiKey" value={apiKey} onChange={() => false} disabled type="textarea" multiline/>
            <CopyToClipboard text={apiKey} onCopy={() => { enqueueSnackbar({ message: t('copied'), variant: 'success' }) }}>
              <Tooltip title={t('copyHint')}>
                <IconButton color="primary" sx={{ mt: 1, ml: 1 }}>
                  <CopyAllOutlined/>
                </IconButton>
              </Tooltip>
            </CopyToClipboard>
          </Stack>
        </Stack>}
      </Box>
      {!user && <Contacts rightPadding="60px"/>}
    </Container>
  )
}